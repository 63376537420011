import React from 'react';

import logo from '../assets/img/logo.png';

function Navigation(){
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
      <a className="navbar-brand" href="/" aria-label="Link to homepage">
        <img src={logo} alt={"FBFI Foundations Logo"} className="img-fluid" />
      </a>
    </nav>
  );
}

export default Navigation;
