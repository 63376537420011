import React, { Component } from 'react';

import {HashRouter as Router, Route, Switch} from "react-router-dom";

import "./fbfi_container_styles.css";

import ScrollToTop from "../components/ScrollToTop/scroll_to_top";
import Navigation from "../components/navbar";
import Footer from "../components/Footer/footer";
import GeneralSession from "./components/GeneralSessionsList/general_sessions";
import Session from "./components/Session/session";

import sessions from "../data/sessions";

class FBFIContainer extends Component {

  state = {
    sessions
  };

  onSessionSelect = (selectedSession) => {
    this.setState({
      selectedSession
    })
  };

  cleanSelectedSession = () => {
    this.setState({
      selectedSession: null
    })
  };

  render() {
    return (
      <Layout>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path={"/"}>
                <GeneralSession sessions={sessions} onSessionSelect={this.onSessionSelect} />
              </Route>
              <Route path={"/session/:id"} render={(props) => <Session sessions={this.state.sessions} {...props} />} />

              <Route render={() => <p>Not Found</p> } />
            </Switch>
          </ScrollToTop>
        </Router>
      </Layout>
    )
  }
}

function Layout({children}){
  return (
    <div>
      <Navigation />

      <div className={"container"}>
        {children}
      </div>

      <Footer />
    </div>
  );
}

export default FBFIContainer;