import React, {Component} from "react";

import {Link} from "react-router-dom";

class Session extends Component {
  render() {

    const selectedSession = this.props.sessions.find(s => s.speaker.id === parseInt(this.props.match.params.id));
    const speaker = selectedSession.speaker;

    return (
      <div className={"row"}>

        <div className={"col-12 d-flex justify-content-between"} style={{marginBottom:10}}>

          <h4>
            {speaker.name}
          </h4>

          <Link to="/" className={"btn btn-primary"}>
            back
          </Link>
        </div>

        <div className={"col col-md-3"}>

          {speaker.image && (
            <img src={`/img/${speaker.image}`} className="img-fluid" alt={`${speaker.name}`} />
          )}

          <audio controls style={{width: 240, marginTop:15}}>
            <source type="audio/mpeg" src={`/audio/${speaker.sermon.audio.src}`} />
            Your browser does not support the <code>audio</code> element.
          </audio>

          {speaker.church.name && (
            <p>
              <a href={`${speaker.church.link}`} rel="noopener">
                {speaker.church.name}
              </a>
            </p>
          )}

          {speaker.bio && (
            <p>
              {speaker.bio}
            </p>
          )}

        </div>

        <div className={"col col-md-8"}>
          <h4>
            {speaker.sermon.title}
          </h4>

          <div className={"transcript"}>
            {speaker.sermon.transcript}
          </div>
        </div>
      </div>
    )
  }
}

export default Session;