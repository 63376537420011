import React, { Component } from 'react';
import './App.css';

import FBFIContainer from "./fbfi/fbfi_container";

class App extends Component {
  render() {
    return React.createElement(FBFIContainer);
  }
}

export default App;
