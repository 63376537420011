import React, {Component} from "react";

import {Link} from "react-router-dom";

class Speaker extends Component {

  sessionSelect = () => {
    this.props.onSessionSelect(this.props.session);
  };

  render() {
    return (
      <li>
        <Link to={`/session/${this.props.speaker.id}`}>
          {this.props.speaker.name} - {this.props.speaker.sermon.title}
        </Link>
      </li>
    );
  }
}

function GeneralSessions({sessions, onSessionSelect}) {
  return (
    <div>
      <h1>Session Audio - 2018</h1>

      <ul style={{listStyle:"none", padding:0}}>
        {sessions.map((session) => <Speaker speaker={session.speaker} key={session.speaker.id} session={session} onSessionSelect={onSessionSelect} /> )}
      </ul>
    </div>
  );
}

export default GeneralSessions;