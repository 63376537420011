import React from "react";

import "./footer_styles.css";

function Footer(){
  return (
    <footer className="footer">
      <div className="container">
        <span className="text-muted">Copyright &copy; FBFI. All rights reserved</span>
      </div>
    </footer>
  );
}

export default Footer;
